import BackLink from "components/common/BackLink";
import IconCheckCircle from "components/common/icon-components/IconCheckCircle";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import DateRow from "components/hybrid-event/DateRow";
import { isExpired } from "components/hybrid-event/util";
import GlobalLayout from "components/page/GlobalLayout";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  PreTitle,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { LearnLine } from "components/webinars/templates/styles/webinarStyles";
import { graphql } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import { styled } from "linaria/react";
import React, { useMemo } from "react";
import Helmet from "react-helmet";
import { Converter } from "showdown";

const Section = styled(SectionContainer)`
  background: var(--gradient-light);
  overflow: visible;
`;

const WebinarInner = styled(SectionInner)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const SectionColumn = styled(SectionCol)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.5rem;
  align-items: ${props => (props.expired ? "center" : "flex-start")};
  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`;

const SectionColumnRight = styled(SectionColumn)`
  align-items: flex-end;
  padding-top: 4.5rem;
`;

const HeroImage = styled.div`
  width: 100%;
  max-width: 668px;
  height: 412px;
  flex-shrink: 0;
  border-radius: 12px;
  background: ${props => `url(${props.url})`};
  background-size: cover;
  @media (max-width: 600px) {
    height: 300px;
  }
`;

const GiveawayCard = styled.div`
  width: 100%;
  max-width: 668px;
  min-height: 227px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 6px 20px 0px rgba(8, 45, 89, 0.15);
  display: flex;
  align-items: center;
  padding: ${props => (props.imageBottom ? "0" : "2.5rem")};
  position: relative;
  overflow: hidden;
  h4 {
    color: var(--Black, #000);
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 200%; /* 40px */
  }
  p:has(a) {
    margin-top: 1.25rem;
  }
  @media (max-width: 1430px) {
    text-align: center;
    flex-direction: column;
    gap: 0;
    p:has(a) {
      margin-top: 0;
    }
  }
`;

const GiveawayImage = styled.img`
  object-position: ${props => props.placement};
  max-width: 420px;
  position: ${props => (props.imageBottom ? "absolute" : "static")};
  right: -1.75rem;
  bottom: 0;
  @media (max-width: 1430px) {
    margin-bottom: ${props => (props.imageBottom ? "0" : "unset")};
    position: static;
  }
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin: 1.25rem 0;
  img {
    width: 171px;
    height: 43px;
    object-fit: contain;
  }
`;

const IntroWebinarTemplate = ({ data }) => {
  const { isMobile } = useIsMobile(1024);
  const {
    markdownRemark: {
      frontmatter: {
        draft,
        metaTitle,
        metaDescription,
        type,
        title,
        description,
        coHostedLogo: { coHostedLogoSrc = null, coHostedLogoAltText = "" } = {},
        formId,
        startDate,
        timeZone,
        heroImage,
        heroImageAltText,
        listTitle,
        expectList,
        giveAwayItem: { itemCopy, itemImage, termsLink, imagePlacement } = {},
      },
    },
  } = data;

  const converter = new Converter();
  const giveAwayItemCopyHTML = converter.makeHtml(itemCopy);
  const descriptionHTML = converter.makeHtml(description);

  const expired = isExpired(startDate);

  const imageBottom = imagePlacement === "bottom";

  const { isMobile: columnView } = useIsMobile(1430);

  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {draft && <meta name="robots" content="noindex, nofollow" />}
        {/* <meta property="og:image" content={ogImage} /> */}
      </Helmet>
      <Section>
        <WebinarInner>
          <SectionColumn expired={expired}>
            <TextContainer width="665px">
              <BackLink text={"Browse Webinars"} path={"/webinars/"} />
              <PreTitle>{type}</PreTitle>
              <FancyPageTitle>{title}</FancyPageTitle>
              <DateRow date={startDate} type={type} timeZone={timeZone} />
              {expired && (
                <MainParagraph style={{ fontWeight: 600 }}>
                  Reach out to{" "}
                  <a href="mailto:sales@rhombus.com">sales@rhombus.com</a> for
                  more information.
                </MainParagraph>
              )}

              {isMobile && !expired && (
                <FormWrapper
                  width="668px"
                  outline
                  style={{ margin: "1.25rem 0" }}
                  title="Register for the Webinar"
                >
                  <RhombusForm formId={formId} />
                </FormWrapper>
              )}
              <div dangerouslySetInnerHTML={{ __html: descriptionHTML }}></div>
              {!!coHostedLogoSrc && (
                <LogoSection>
                  <TitleSmall>Co-Hosted by</TitleSmall>
                  <img src={coHostedLogoSrc} alt={coHostedLogoAltText} />
                </LogoSection>
              )}
            </TextContainer>
            {!isMobile && <HeroImage url={heroImage} alt={heroImageAltText} />}
            {!expired && (
              <TextContainer width="665px">
                <TitleSmall>{listTitle}</TitleSmall>
                <ul style={{ padding: 0 }}>
                  {expectList.map(item => {
                    const html = converter.makeHtml(item);
                    return (
                      <LearnLine>
                        <IconCheckCircle />
                        <div dangerouslySetInnerHTML={{ __html: html }}></div>
                      </LearnLine>
                    );
                  })}
                </ul>
              </TextContainer>
            )}
            {!!itemCopy && !expired && (
              <GiveawayCard imageBottom={imageBottom}>
                <TextContainer
                  width="340px"
                  style={
                    imageBottom && !columnView
                      ? { padding: "2.5rem 0 2.5rem 2.5rem" }
                      : columnView && imageBottom
                      ? { padding: "2.5rem 1.25rem" }
                      : {}
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: giveAwayItemCopyHTML }}
                  ></div>
                  <MainParagraph>
                    *
                    <a href={termsLink} target="_blank">
                      Terms and conditions
                    </a>{" "}
                    apply
                  </MainParagraph>
                </TextContainer>
                <GiveawayImage
                  src={itemImage}
                  alt="Rhombus Giveaway"
                  placement={imagePlacement}
                  imageBottom={imageBottom}
                />
              </GiveawayCard>
            )}
          </SectionColumn>
          {!isMobile && !expired && (
            <SectionColumnRight>
              <FormWrapper sticky title="Register for the Webinar">
                <RhombusForm formId={formId} />
              </FormWrapper>
            </SectionColumnRight>
          )}
        </WebinarInner>
      </Section>
    </GlobalLayout>
  );
};

export const PAGE_QUERY = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        draft
        metaTitle
        metaDescription
        type
        title
        description
        coHostedLogo {
          coHostedLogoSrc
          coHostedLogoAltText
        }
        formId
        startDate
        timeZone
        heroImage
        heroImageAltText
        expectList
        listTitle
        giveAwayItem {
          itemCopy
          itemImage
          termsLink
          imagePlacement
        }
      }
    }
  }
`;

export default IntroWebinarTemplate;
